import styled from "@emotion/styled";

type RotationProps = {
  rotation: number;
  topOffset: number;
  leftOffset: number;
};

const Polaroid = styled.div<RotationProps>`
  a {
    background: #ffffff;
    display: inline-block;
    margin: 55px 75px 30px;
    padding: 15px 15px 30px;
    text-align: center;
    text-decoration: none;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    transition: all 0.2s linear;
    z-index: 0;
    position: relative;
    transform: rotateZ(${(x) => x.rotation}deg);
    top: ${(x) => x.topOffset}px;
    left: ${(x) => x.leftOffset}px;
  }

  a:after {
    color: #333;
    font-size: 25px;
    content: attr(data-caption);
    position: relative;
    top: 15px;
  }

  img {
    display: block;
    width: 300px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 700px) and (orientation: portrait) {
    img {
      display: block;
      width: 100px;
    }

    a:after {
      font-size: 12px;
    }

    a {
      top: ${(x) => x.topOffset / 10}px;
      left: ${(x) => x.leftOffset / 10}px;
    }
  }

  a:hover {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    transform: scale(1.2);
    z-index: 10;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
  }
`;

export const Picture = ({
  rotation,
  srcImg,
  caption,
  topOffset,
  leftOffset,
}) => {
  return (
    <li>
      <Polaroid
        rotation={rotation}
        topOffset={topOffset}
        leftOffset={leftOffset}
      >
        <a
          href="https://capturedbykacie61.pixieset.com/saraengagement/"
          style={{ fontFamily: "damion" }}
          title={"Click Me!"}
          data-caption={caption}
        >
          <img height="auto" alt={caption} src={srcImg}></img>
        </a>
      </Polaroid>
    </li>
  );
};
