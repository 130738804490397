import styled from "@emotion/styled";
import { Picture } from "../Picture";
import woodBG from "../assets/wood-bg.jpg";

const VideoContainer = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
`;

const Registry = styled.a`
  border: 1px solid gold;
  font-family: serif;
  color: gold;
  text-decoration: unset;
  border-radius: 2px;
  padding: 6px;
  text-shadow: 0px 0px 9px black;
  opacity: 0.75;
  font-size: 2.25vmin;

  :hover {
    opacity: 0.9;
  }
`;

const Page = styled.div`
  overflow: hidden;
  position: fixed;
  z-index: -2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: transform linear;
  transition: transform linear, -webkit-transform linear;
`;

const ContentWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.1);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100vh;

  > div > h1 {
    margin: -9.25vmin 0 0;
    width: 100%;
    font-size: 9.25vmin;
    letter-spacing: 0.33vmin;
    color: rgb(255, 255, 255);
    opacity: 1;
  }
`;

const BodyWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(${woodBG});
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .path {
    stroke-dasharray: 20;
    stroke-dashoffset: 100;
    animation: dash 10s linear backwards infinite;
  }

  @keyframes dash {
    to {
      stroke-dashoffset: -1000;
    }
  }
`;

export const Home = () => {
  return (
    <>
      <ContentWrapper>
        <div
          style={{
            textAlign: "center",
            fontFamily: "Tangerine",
            fontSize: "32px",
          }}
        >
          <h1>#DestinationWedding</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "15px",
            }}
          >
            <Registry href="https://www.target.com/gift-registry/gift-giver?registryId=3df199b0-267b-11ed-acc8-95a895376a0d&type=WEDDING">
              View Target Registry
            </Registry>
            <Registry href="https://www.amazon.com/wedding/nathan-murphy-sarah-crawford--december-2022/registry/176XHA5OGIA8V">
              View Amazon Registry
            </Registry>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Registry href="https://capturedbykacie61.pixieset.com/saraengagement/">
              View Engagement Photos 📷
            </Registry>
          </div>
        </div>
        <Page>
          <VideoContainer autoPlay loop muted playsInline>
            <source
              src={require("../assets/Video_Reel.mp4")}
              type="video/mp4"
            />
          </VideoContainer>
        </Page>
      </ContentWrapper>
      <BodyWrapper>
        <ul>
          <Picture
            srcImg={require("../assets/sarah-and-I.jpg")}
            caption={"The Love of My Life"}
            topOffset={-20}
            leftOffset={0}
            rotation={-2}
          ></Picture>
          <Picture
            srcImg={require("../assets/20201218_161727.jpg")}
            caption={"She Said Yes!"}
            topOffset={-100}
            leftOffset={150}
            rotation={-4}
          ></Picture>
          <Picture
            srcImg={require("../assets/IMG_4647.jpg")}
            caption={"Phi Theta Kappa - 2018"}
            topOffset={-200}
            leftOffset={0}
            rotation={27}
          ></Picture>
        </ul>

        <ul>
          <Picture
            srcImg={require("../assets/rtc-snapshot-5860637799553655008.jpeg")}
            caption={"Video Chat Quarantine Vibes"}
            rotation={33}
            topOffset={100}
            leftOffset={-12}
          ></Picture>
          <Picture
            srcImg={require("../assets/Halloween.jpg")}
            caption={"Halloween!"}
            rotation={9}
            topOffset={2}
            leftOffset={25}
          ></Picture>
          <Picture
            srcImg={require("../assets/Love.jpg")}
            caption={"Best. Party. Ever..."}
            rotation={-8}
            topOffset={-32}
            leftOffset={100}
          ></Picture>
        </ul>
        <a
          style={{ width: "44%", height: "44%" }}
          href="https://www.google.com/maps/dir/Louisiana/Pigeon+Forge,+TN/@33.5840092,-87.7648864,7z/data=!4m8!4m7!1m2!1m1!1s0x8620a454b2118265:0xc955f73281e54703!1m2!1m1!1s0x885bff0da7e6bdcb:0x1770c0708e28804e!3e0"
        >
          <img
            width={"100%"}
            height="100%"
            style={{
              marginTop: "99px",
              // boxShadow: "0 10px 20px rgba(0, 0, 0, 0.7)",
              transform: "rotateZ(2deg)",
            }}
            alt="ipad with google maps to TN"
            src={require("../assets/ipad-2-tennesse.png")}
          ></img>
        </a>
      </BodyWrapper>
      <footer>
        <div
          style={{
            display: "flex",
            background: "black",
            color: "#eee",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              display: "flex",
              background: "black",
              color: "#eee",
              flexDirection: "column",
            }}
          >
            <h4>Soon to be Mr. & Mrs. Murphy - 12/12/2022</h4>
            <p>Website designed by Nathan Murphy </p>
            <span>nmurphy.professional@gmail.com</span>
            <p></p>
          </div>
          <div>
            <h4>Quick Links</h4>
            <ul>
              <li>
                <a
                  style={{ color: "white" }}
                  href="https://www.buymeacoffee.com/NateDogOnTheWeb"
                >
                  Buy me a ☕!
                </a>
              </li>
              <li>
                <a
                  style={{ color: "white" }}
                  href="https://www.amazon.com/wedding/nathan-murphy-sarah-crawford--december-2022/registry/176XHA5OGIA8V"
                >
                  Our Amazon Registry
                </a>
              </li>
              <li>
                <a
                  style={{ color: "white" }}
                  href="https://www.target.com/gift-registry/gift-giver?registryId=3df199b0-267b-11ed-acc8-95a895376a0d&type=WEDDING"
                >
                  Our Target Registry
                </a>
              </li>
              <li>
                <a
                  style={{ color: "white" }}
                  href="https://www.google.com/maps/dir/Louisiana/Pigeon+Forge,+TN/@33.5840092,-87.7648864,7z/data=!4m8!4m7!1m2!1m1!1s0x8620a454b2118265:0xc955f73281e54703!1m2!1m1!1s0x885bff0da7e6bdcb:0x1770c0708e28804e!3e0"
                >
                  Our Destination
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};
